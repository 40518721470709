/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
      // JavaScript to be fired on all pages

      //toggle search
      $( ".js--libby-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).addClass( "libby-search-overlay-wrapper--is-open" );
      });

      $( ".js-libby-close-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).removeClass( "libby-search-overlay-wrapper--is-open" );
      });

      //Create Owl Carousel for Hero
      jQuery('.libby_hero_slider').owlCarousel({
        items: 1,
        loop: true,
        infinite: true,
        lazyLoad: true,
        dots: true,
        dotsEach: true,
        autoPlay: true,
        responsiveClass: true,
        responsive: true,
        singleItem: true,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true,
        navigation: true,
        navigationText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
      });

      //Create Owl Carousel for Small Slider Hero
      jQuery('.libby_hero_sm_slider').owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        dots: false,
        autoPlay: true,
        responsive: false,
        navigation: true,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true
      });

      //Create Owl Carousel for EResources
      jQuery('.libby-er-slider').owlCarousel({
        items: 4,
        loop: true,
        lazyLoad: true,
        dots: false,
        autoPlay: false,
        responsive: true,
        navigation: false,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true
      });

      //Create Owl Carousel for Information Services
      jQuery('.libby-is-slider').owlCarousel({
        items: 3,
        margin: 20,
        stagePadding: 20,
        loop: true,
        lazyLoad: true,
        dots: true,
        autoPlay: false,
        responsive: true,
        navigation: true,
        navigationText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
      });
      
      //header search
      (function($) {
        var catalogSearchURL = $('.libby-searchform').attr('action'), input;

        $('.libby-searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_site' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
          }
        });
        
        $(".button_catalog").click(".button_catalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-s').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_site").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('input.libby-s').attr('placeholder', 'Search the website');
        });

      })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //Typing Effect
        var TxtRotate = function(el, toRotate, period) {
          this.toRotate = toRotate;
          this.el = el;
          this.loopNum = 0;
          this.period = parseInt(period, 10) || 7500;
          this.txt = '';
          this.tick();
          this.isDeleting = false;
        };

        TxtRotate.prototype.tick = function() {
          var i = this.loopNum % this.toRotate.length;
          var fullTxt = this.toRotate[i];

          if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
          } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
          }

          this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

          var that = this;
          var delta = 300 - Math.random() * 100;

          if (this.isDeleting) { delta /= 2; }

          if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
          } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 1500;
          }

          setTimeout(function() {
            that.tick();
          }, delta);
        };

        window.onload = function() {
          var elements = document.getElementsByClassName('txt-rotate');
          for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-rotate');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtRotate(elements[i], JSON.parse(toRotate), period);
            }
          }
          // INJECT CSS
          var css = document.createElement("style");
          css.type = "text/css";
          css.innerHTML = ".txt-rotate > .wrap { border-right: 0.05em solid #ddd }";
          document.body.appendChild(css);
        };

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
